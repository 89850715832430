import styled from 'styled-components';
import { transparentize } from 'polished';
import styledMap from 'styled-map';

import theme from 'styles/theme';
import { H1, Text } from 'components/Text';
import { Grid } from 'components/Grid';
import { Image as StyledImage } from 'components/Media';

import DownloadIcon from 'img/download-white.svg';

export const Wrapper = styled.section`
  background-image: ${props =>
    props.category === 'Experience'
      ? `linear-gradient(
    to bottom,
    ${transparentize(0.9, theme.color.teal)} 0,
    white 500px
  )`
      : null};
`;

export const Inner = styled(Grid)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${theme.size.tablet}) {
    flex-direction: column;
  }
`;

export const Hero = styled.div`
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${theme.color.paper2};
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  width: calc(100% - 500px);
  padding: 70px 50px 70px 0;

  @media (max-width: ${theme.size.desktop}) {
    width: 100%;
    padding: 70px 50px 70px 0;
  }

  @media (max-width: ${theme.size.tablet}) {
    width: 100%;
    padding: 40px 0;
    order: 2;
  }

  @media (max-width: ${theme.size.mobile}) {
    padding-bottom: 0;
  }
`;

export const Title = styled(H1)`
  margin-bottom: 25px;

  @media (max-width: ${theme.size.tablet}) {
    margin-bottom: 15px;
  }
`;

export const Description = styled(Text)`
  max-width: 800px;
  font-size: ${theme.font.size.theta};
`;

export const ImageLink = styled.a`
  width: 500px;
  display: block;
  margin: 100px 0 0;

  @media (max-width: ${theme.size.desktop}) {
    width: ${props => (props.isFile ? '300px' : '600px')};
  }

  @media (max-width: ${theme.size.tablet}) {
    margin-top: 30px;
    height: auto;
    width: ${props => (props.isFile ? '300px' : '100%')};
  }

  @media (max-width: ${theme.size.mobile}) {
    width: ${props => (props.isFile ? '200px' : '100%')};
  }
`;

export const Image = styled(StyledImage)`
  display: block;
  width: 100%;
  margin: 0;
  position: relative;
  border-radius: ${theme.radius};
  border: ${props =>
    props.isFile ? `1px solid ${theme.color.paper}` : 'none'};

  @media (max-width: ${theme.size.tablet}) {
    max-width: 500px;
    max-height: 300px;
  }

  @media (max-width: ${theme.size.mobile}) {
    max-height: 250px;
  }

  &:hover::after {
    opacity: ${props => (props.isFile ? '1' : '0')};
  }

  &::after {
    content: '';
    opacity: 0;
    backdrop-filter: blur(2px);
    transition: ${theme.transition};
    background-repeat: no-repeat;
    background-color: ${transparentize(0.3, theme.color.teal)};
    background-image: url(${DownloadIcon});
    background-position: center;
    background-size: 100px auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Category = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${theme.color.teal};
  font-weight: ${theme.font.weight.bold};
  background-color: ${transparentize(0.9, theme.color.teal)};
  padding: 7px 9px 7px 12px;
  margin-bottom: 30px;
  display: table;
  font-size: ${theme.font.size.kappa};
  border-radius: ${theme.radius};

  background-color: ${styledMap('category', {
    Experience: theme.color.teal,
    default: transparentize(0.9, theme.color.teal)
  })};

  color: ${styledMap('category', {
    Experience: 'white',
    default: theme.color.teal
  })};
`;

export const ActionBlock = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
