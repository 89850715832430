import React from 'react';

import { Wrapper, Title, Text } from './styles';

const Contents = ({ content }) => {
  return (
    <Wrapper>
      <Title>Includes:</Title>
      {content.map((item, index) => (
        <Text key={index} count={index + 1}>
          {item.text}
        </Text>
      ))}
    </Wrapper>
  );
};

export default Contents;
