import React from 'react';

import { markdownify } from 'utils';

import { Wrapper, Heading, Item, Title, Text } from './styles';

const Purposes = ({ content }) => {
  return (
    <Wrapper>
      <Title>Purpose:</Title>
      {content.map((item, index) => (
        <Item key={index}>
          <Heading>{item.heading}</Heading>
          <Text dangerouslySetInnerHTML={{ __html: markdownify(item.text) }} />
        </Item>
      ))}
    </Wrapper>
  );
};

export default Purposes;
