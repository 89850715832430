import React from 'react';
import PropTypes from 'prop-types';
import { graphql, withPrefix } from 'gatsby';

import Breadcrumbs, { Breadcrumb } from 'components/Breadcrumbs';
import Layout from 'components/Layout';
import Protected from 'components/Protected';
import Button from 'components/Button';

import {
  Wrapper,
  Content,
  Inner,
  Title,
  Hero,
  Description,
  Image,
  Category,
  ImageLink
} from './styles';
import Summary from './Summary';
import Contents from './Contents';
import Purposes from './Purposes';

export const LabResourcePageTemplate = ({ page, location }) => {
  const parentTitle = location.state ? location.state.parentTitle : null;
  const parentSlug = location.state ? location.state.parentSlug : null;
  const { contents, purposes, summary } = page.content;

  const { file } = page.actions.downloadButton;

  /* eslint-disable */
  // const filename = file.replace(/^.*[\\\/]/, "");
  /* eslint-enable */

  return (
    <Protected>
      <Breadcrumbs>
        <Breadcrumb to="/">Intelligence Lab</Breadcrumb>
        {parentSlug && parentTitle && (
          <Breadcrumb to={parentSlug}>{parentTitle}</Breadcrumb>
        )}
        <Breadcrumb as="span">{page.page.title}</Breadcrumb>
      </Breadcrumbs>
      <Wrapper category={page.category}>
        <Inner>
          <Content>
            <Hero>
              {page.category && (
                <Category category={page.category}>{page.category}</Category>
              )}
              <Title>{page.page.title}</Title>
              <Description>{page.page.description}</Description>
              {file && (
                <Button
                  mt="35px"
                  mr="10px"
                  as="a"
                  shade="primary"
                  target="_blank"
                  to={withPrefix(`media/${file.relativePath}`)}
                >
                  {page.actions.downloadButton.text || 'Download'}
                </Button>
              )}
              {page.actions.actionButton && page.actions.actionButton.path && (
                <Button mt="35px" to={page.actions.actionButton.path}>
                  {page.actions.actionButton.text || 'Find out more'}
                </Button>
              )}
            </Hero>
            {summary &&
              summary.map((item, index) => (
                <Summary content={item} key={index} />
              ))}
            {contents && <Contents content={contents} />}
            {file && (
              <Button
                mt="-15px"
                mb="50px"
                as="a"
                shade="primary"
                target="_blank"
                to={withPrefix(`media/${file.relativePath}`)}
              >
                {page.actions.downloadButton.text || 'Download'}
              </Button>
            )}
            {purposes && <Purposes content={purposes} />}
          </Content>
          {page.category === 'Experience' ? (
            <ImageLink as="div">
              <Image content={page.page.resourceImage || page.page.image} />
            </ImageLink>
          ) : (page.page.resourceImage || page.page.image) && file ? (
            <>
              <ImageLink href={withPrefix(`media/${file.relativePath}`)}>
                <Image
                  isFile={file}
                  content={page.page.resourceImage || page.page.image}
                />
              </ImageLink>
            </>
          ) : page.page.resourceImage || page.page.image ? (
            <ImageLink as="div">
              <Image
                isFile={file}
                content={page.page.resourceImage || page.page.image}
              />
            </ImageLink>
          ) : null}
        </Inner>
      </Wrapper>
    </Protected>
  );
};

// LabResourcePageTemplate.propTypes = {
//   title: PropTypes.string
// };

const LabResourcePage = ({ data, location }) => {
  const { frontmatter: page, html: markdown } = data.markdownRemark;

  return (
    <Layout content={page} shade="lab">
      <LabResourcePageTemplate
        page={page}
        markdown={markdown}
        location={location}
      />
    </Layout>
  );
};

LabResourcePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default LabResourcePage;

export const labResourcePageQuery = graphql`
  query LabResourcePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        page {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 40) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          resourceImage {
            childImageSharp {
              fluid(maxWidth: 800, quality: 40) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        category
        related
        content {
          contents {
            text
          }
          purposes {
            heading
            text
          }
          summary {
            heading
            text
          }
        }
        actions {
          actionButton {
            path
            text
          }
          downloadButton {
            text
            file {
              relativePath
            }
          }
        }
      }
    }
  }
`;
