import styled from 'styled-components';

import theme from 'styles/theme';
import { Text as StyledText, H4 } from 'components/Text';

export const Wrapper = styled.div`
  margin-top: 5px;
  padding-bottom: 50px;
  padding-top: 45px;
  border-top: 1px solid ${theme.color.paper2};
`;

export const Title = styled(H4)`
  margin-bottom: 0;
`;

export const Text = styled(StyledText)`
  margin-top: 15px;
  position: relative;
  padding-left: 0;

  &::before {
    content: '';
    position: absolute;
    left: -20px;
    top: 8px;
    height: 14px;
    width: 14px;
    background-image: url(/media/file-teal.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
`;
