import styled from 'styled-components';
import { Text as StyledText, H4 } from 'components/Text';

export const Wrapper = styled.div`
  padding-bottom: 40px;
`;

export const Heading = styled(H4)``;

export const Text = styled(StyledText)``;
