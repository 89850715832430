import React from 'react';

import { markdownify } from 'utils';

import { Wrapper, Heading, Text } from './styles';

const Summary = ({ content }) => {
  return (
    <Wrapper>
      <Heading>{content.heading}</Heading>
      <Text dangerouslySetInnerHTML={{ __html: markdownify(content.text) }} />
    </Wrapper>
  );
};

export default Summary;
