import styled from 'styled-components';
import { darken, tint } from 'polished';

import theme from 'styles/theme';
import { Text as StyledText, H4, H5 } from 'components/Text';

export const Wrapper = styled.div`
  padding: 40px 35px;
  margin: 0 -35px;
  border-radius: ${theme.radius};
  background-color: ${tint(0.95, theme.color.teal)};
`;

export const Title = styled(H4)`
  margin-bottom: 30px;
`;

export const Heading = styled(H5)`
  color: ${theme.color.teal};
  margin-bottom: 5px;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.delta};
`;

export const Text = styled(StyledText)`
  color: ${darken(0.05, theme.color.teal)};
`;

export const Item = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;
